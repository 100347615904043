

















































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { Manifest } from "../interfaces/manifest";

@Component
export default class AddToHomeTuto extends Vue {
  @Prop(Boolean) dialog: boolean;

  @Prop({ type: Boolean, default: true }) showButton: boolean;
  @Prop({ type: Boolean, default: true }) showCheckbox: boolean;
  @Prop({ type: Boolean, default: false }) showCross: boolean;

  @Prop(String) type: "lobby" | "game";

  checkboxValue = false;
  oldManifest: Manifest | undefined;

  get show(): boolean {
    return this.dialog;
  }

  set show(value: boolean) {
    this.$emit("close");
    this.$store.direct.commit.mobile.SET_NO_MORE_TUTO(this.checkboxValue);
  }
}
