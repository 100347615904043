





























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class Header extends Vue {
  @Prop(String) readonly img: string | undefined;
  @Prop(String) readonly height: string | undefined;
  @Prop({ type: Boolean, default: true }) readonly gradient: boolean;
  @Prop({ type: Boolean, default: true }) readonly backButton: boolean;

  get proeminent(): boolean {
    return true;
    // return this.$store.direct.state.mobile.orientation == OrientationMode.PORTRAIT;
  }
}
